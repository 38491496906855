// color variants
@import 'themes-vars.module.scss';

// third-party
@import 'react-perfect-scrollbar/dist/css/styles.css';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

@import 'react-18-image-lightbox/style.css';
@import 'react-responsive-carousel/lib/styles/carousel.min.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  left: -4px !important;
  right: -4px !important;
}

.apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series,
.apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
  gap: 8px;
}
.apexcharts-legend-series {
  display: flex;
  gap: 8px;
}

html[dir='rtl'] .apexcharts-tooltip .apexcharts-tooltip-marker {
  margin-left: 10px;
  margin-right: 0px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

.preBuildDashBoard-slider {
  overflow: hidden;
  .slider {
    .slide {
      opacity: 0.5;
      display: flex;
      justify-content: center;
      .custom-slider {
        gap: 40px;
      }
      &.selected {
        opacity: 1;
        .custom-slider {
          display: flex;
          flex-direction: column;
        }
      }
      &:not(.selected) {
        transform: scale(0.7);
        transform-origin: top;
      }
    }
  }
}

.project-info {
  .project-slider {
    .slick-list {
      padding: 0 !important;
    }
  }
  .slick-slide {
    opacity: 0.05;
  }
  .slick-active {
    opacity: 0.2;
  }
  .slick-current {
    opacity: 1 !important;
    .MuiTypography-root {
      color: $primaryMain;
    }
  }
}
.ril__inner {
  direction: ltr;
}
.ReactModal__Overlay {
  z-index: 99999 !important;
}

// ==============================|| MAP STYLES ||============================== //

.map-container-ligth,
.map-container-ligth .leaflet-tile-pane,
.map-container-ligth .leaflet-tile-container,
.map-container-ligth .leaflet-tile {
  background-color: #dddddd;
}

.map-container,
.map-container .leaflet-tile-pane,
.map-container .leaflet-tile-container,
.map-container .leaflet-tile {
  background-color: #d4d4d4;
}

.popup-dark .leaflet-popup-content-wrapper,
.popup-dark .leaflet-popup-tip {
  background-color: #14252f;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

img.marker {
  outline: 2px ridge blue;
  border-radius: 5rem;
}

img.inspected {
  outline: 2px ridge green;
  border-radius: 5rem;
}

img.partialinspected {
  outline: 2px ridge orange;
  border-radius: 5rem;
}

img.uninspected {
  outline: 2px ridge yellow;
  border-radius: 5rem;
}

// img.alarmRed {
//   outline: 2px ridge red;
//   border-radius: 5rem;
// }

.alarmRed {
  outline: 2px ridge rgb(250, 1, 1);
  border-radius: 25rem;
  box-shadow: 0 0 0 0 rgb(250, 1, 1);
  transform: scale(5);
  animation: alarmRed 2s infinite;
}

@keyframes alarmRed {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(250, 1, 1, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(250, 1, 1, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(250, 1, 1, 0);
  }
}

.alarmYellow {
  outline: 2px ridge yellow;
  border-radius: 25rem;
  box-shadow: 0 0 0 0 yellow;
  transform: scale(5);
  animation: alarmYellow 2s infinite;
}

@keyframes alarmYellow {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 yellow;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(250, 1, 1, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(250, 1, 1, 0);
  }
}

.alarmOrange {
  outline: 2px ridge orange;
  border-radius: 25rem;
  box-shadow: 0 0 0 0 orange;
  transform: scale(5);
  animation: alarmOrange 2s infinite;
}

@keyframes alarmOrange {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 orange;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(250, 1, 1, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(250, 1, 1, 0);
  }
}

// ==============================|| CUSTOMIZATIONS ||============================== //

body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  //   -webkit-touch-callout: none;
  //   -ms-text-size-adjust: none;
  //   -webkit-text-size-adjust: none;
  //   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.svg-border-black {
  border: 1px, solid, black;
}

.svg-layer-hidden {
  display: none;
}

.svg-border-red {
  border: 1px, dashed, red;
}

.svg-border-blue {
  border: 1px, dashed, blue;
}
